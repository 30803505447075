// Add default theme for ng-select
@import '@ng-select/ng-select/themes/default.theme.css';

// Remove rounded corners
.ng-select .ng-select-container,
.ng-dropdown-panel.ng-select-bottom,
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  border-radius: 0;
}

// Make height the same as bootstrap
.ng-select.ng-select-single .ng-select-container {
  height: 38px;
}

// Placeholder colour
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $gray-600;
}

// Border colour
.ng-select .ng-select-container,
.ng-select.ng-select-opened > .ng-select-container {
  border-color: $gray-400;
}

// Focus styles
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: tint-color($primary, 50%);
  box-shadow: 0 0 0 0.25rem rgba($primary, 0.25);
}

// Hovered and selected options in drop down
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: $frost;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $frost;
}

// Multiple selected options
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  outline: 1px solid $cloud;
  background-color: $frost;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon.left {
  border-color: $cloud;
}
.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value
  .ng-value-icon:hover {
  background-color: $cloud;
}

// Clear all options button
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: $danger;
}

// Allow dropdown to extend past the select container
// so items with long names can be seen
.ng-dropdown-panel {
  width: auto !important;
  max-width: 550px;
  min-width: 100%;
}
