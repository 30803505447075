@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Colours */
    --color-night: #27242c;
    --color-night-2: #514d55;
    --color-night-3: #7d7a80;
    --color-night-4: #a8a7aa;
    --color-cloud: #e1e3f0;
    --color-cloud-2: #e7e8f3;
    --color-cloud-3: #edeef6;
    --color-cloud-4: #f3f3f8;
    --color-slate: #abb4be;
    --color-slate-2: #c3cbd2;
    --color-slate-3: #d9dfe5;
    --color-slate-4: #eaeef2;
    --color-leaf: #25d2ab;
    --color-leaf-2: #00ddb9;
    --color-leaf-3: #4be6cb;
    --color-leaf-4: #91eedd;
    --color-sun: #fa7f51;
    --color-sun-2: #ff9b76;
    --color-sun-3: #ffbda5;
    --color-sun-4: #ffddd0;
    --color-raspberry: #ff4e5c;
    --color-raspberry-2: #ff6672;
    --color-raspberry-3: #ff8d96;
    --color-raspberry-4: #ffd9dc;
    --color-yellow: #ffdd6a;
    --color-yellow-2: #ffeba9;
    --color-yellow-3: #fff3cc;
    --color-yellow-4: #fff9e5;
    --color-sky: #73b0f4;
    --color-sky-2: #93bff0;
    --color-sky-3: #a6cbf5;
    --color-sky-4: #d2e4f8;

    --color-viz-negative-rgb: #b2182b;
    --color-viz-neutral-rgb: #dedee3;
    --color-viz-positive-rgb: #2166ac;

    --color-viz-negative: var(--color-viz-negative-rgb);
    --color-viz-neutral: var(--color-viz-neutral-rgb);
    --color-viz-positive: var(--color-viz-positive-rgb);

    --color-black: #000;
    --color-white: #fff;

    --color-success: #1da788;
    --color-danger: #e70012;

    --color-transparent: transparent;
    --color-current: currentColor;
  }

  @font-face {
    font-family: 'TTInterphases';
    font-weight: 100;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Light/font.woff')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Light/font.woff2')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTInterphases';
    font-weight: 300;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Regular/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Regular/font.woff')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTInterphases';
    font-weight: 400;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Medium/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Medium/font.woff')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTInterphases';
    font-weight: 700;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Bold/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Bold/font.woff')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTInterphases';
    font-weight: 900;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Black/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTInterphases-Black/font.woff')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTFirsNeue';
    font-weight: 700;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTFirsNeueDemiBold/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTFirsNeueDemiBold/font.woff')
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'TTFirsNeue';
    font-weight: 900;
    src:
      url('https://assets.humanmademachine.com/hmm/webFonts/TTFirsNeueBold/font.woff2')
        format('woff2'),
      url('https://assets.humanmademachine.com/hmm/webFonts/TTFirsNeueBold/font.woff')
        format('woff');
    font-display: swap;
  }
}

@layer utilities {
  .tw-stretched-link::after {
    @apply tw-pointer-events-auto tw-absolute tw-inset-0 tw-z-10 tw-bg-transparent tw-content-[''];
  }
}

@layer components {
  .hmm-switch {
    @apply tw-inline-flex tw-gap-1 tw-border tw-border-cloud tw-p-1;
  }

  .hmm-switch-btn {
    @apply tw-inline-flex tw-cursor-pointer tw-select-none tw-items-center tw-gap-2 tw-px-4 tw-py-3 tw-font-sans tw-text-sm tw-leading-none tw-text-night tw-no-underline;
  }

  .hmm-switch-btn:hover {
    @apply tw-bg-cloud-4;
  }

  .hmm-switch-btn:focus-visible {
    @apply tw-bg-cloud-2;
  }

  .hmm-switch-btn:disabled {
    @apply tw-bg-cloud-4 tw-text-night-3;
  }

  .hmm-switch-btn.hmm-switch-btn-active {
    @apply tw-bg-night tw-text-white;
  }

  .tw-btn {
    @apply tw-inline-flex tw-cursor-pointer tw-select-none tw-items-center tw-gap-3 tw-px-4 tw-py-3 tw-text-center tw-font-heading tw-text-sm tw-leading-none tw-no-underline;
  }

  .tw-btn {
    @apply tw-bg-cloud-2 tw-text-night;
  }

  .tw-btn:focus {
    @apply tw-outline tw-outline-offset-2 tw-outline-night-2;
  }

  .tw-btn:focus:not(:focus-visible) {
    @apply tw-outline-none;
  }

  .tw-btn:hover {
    @apply tw-bg-cloud tw-text-night;
  }

  .tw-btn:active {
    @apply tw-bg-cloud-4 tw-text-night;
  }

  .tw-btn:disabled,
  .tw-btn-disabled,
  .tw-btn-disabled:hover {
    @apply tw-cursor-not-allowed tw-bg-cloud-4 tw-text-night-4;
  }

  .tw-btn-sm {
    @apply tw-px-3 tw-py-2;
  }

  .tw-btn-primary {
    @apply tw-bg-leaf tw-text-white;
  }

  .tw-btn-primary:hover {
    @apply tw-bg-leaf-2 tw-text-white;
  }

  .tw-btn-primary:active {
    @apply tw-bg-leaf-3 tw-text-white;
  }

  .tw-btn-primary:disabled,
  .tw-btn-primary.tw-btn-disabled,
  .tw-btn-primary.tw-btn-disabled:hover {
    @apply tw-bg-leaf-4 tw-text-white;
  }

  .tw-btn-outline {
    @apply tw-border tw-border-cloud-2 tw-bg-white tw-text-night;
  }

  .tw-btn-outline:hover {
    @apply tw-bg-cloud-4 tw-text-night;
  }

  .tw-btn-outline:active {
    @apply tw-border-cloud-4 tw-bg-white tw-text-night;
  }

  .tw-btn-outline:disabled,
  .tw-btn-outline.tw-btn-disabled,
  .tw-btn-outline.tw-btn-disabled:hover {
    @apply tw-border-cloud-2 tw-bg-white tw-text-night-4;
  }

  .tw-btn-danger {
    @apply tw-border tw-border-raspberry tw-bg-white tw-text-raspberry;
  }

  .tw-btn-danger:hover {
    @apply tw-bg-raspberry tw-text-white;
  }

  .tw-btn-danger:active {
    @apply tw-bg-raspberry-2 tw-text-white;
  }

  .tw-btn-danger:disabled,
  .tw-btn-danger.tw-btn-disabled,
  .tw-btn-danger.tw-btn-disabled:hover {
    @apply tw-border-raspberry-4 tw-bg-white tw-text-raspberry-3;
  }

  .tw-btn-dark {
    @apply tw-bg-night tw-text-white;
  }

  .tw-btn-dark:hover {
    @apply tw-bg-night-2 tw-text-white;
  }

  .tw-btn-dark:active {
    @apply tw-bg-night-3 tw-text-white;
  }

  .tw-btn-dark:disabled,
  .tw-btn-dark.tw-btn-disabled,
  .tw-btn-dark.tw-btn-disabled:hover {
    @apply tw-bg-night-3 tw-text-white;
  }

  .tw-link {
    @apply tw-inline-flex tw-items-center tw-gap-1.5 tw-text-night tw-underline;
  }

  .tw-link:hover {
    @apply tw-text-night-2;
  }

  .tw-link:focus {
    @apply tw-text-night;
  }

  .tw-link:disabled {
    @apply tw-text-night tw-no-underline;
  }

  .tw-link-white {
    @apply tw-text-white;
  }

  .tw-link-white:hover {
    @apply tw-text-cloud-2;
  }

  .tw-link-white:focus {
    @apply tw-text-white;
  }

  .hmm-bg-hatched-cloud {
    @apply tw-bg-[length:8px_8px];
    background-image: repeating-linear-gradient(
      135deg,
      #e1e3f0 0,
      #e1e3f0 2px,
      #f3f3f8 0,
      #f3f3f8 50%
    );
  }

  .hmm-bg-hatched-cloud-4 {
    @apply tw-bg-[length:8px_8px];
    background-image: repeating-linear-gradient(
      135deg,
      #fff 0,
      #fff 2px,
      #f3f3f8 0,
      #f3f3f8 50%
    );
  }

  .hmm-bg-hatched-night {
    @apply tw-bg-[length:8px_8px];
    background-image: repeating-linear-gradient(
      135deg,
      #b6bdc6 0,
      #b6bdc6 2px,
      #f3f3f8 0,
      #f3f3f8 50%
    );
  }

  .hmm-bg-hatched-leaf-4 {
    @apply tw-bg-[length:8px_8px];
    background-image: repeating-linear-gradient(
      135deg,
      #91eedd 0,
      #91eedd 2px,
      #fff 0,
      #fff 50%
    );
  }

  .hmm-viz-gradient-rgb {
    background: linear-gradient(
      90deg,
      var(--color-viz-negative-rgb) 0%,
      var(--color-viz-neutral-rgb) 45%,
      var(--color-viz-neutral-rgb) 55%,
      var(--color-viz-positive-rgb) 100%
    );
  }

  .hmm-viz-gradient {
    background: linear-gradient(
      90deg,
      var(--color-viz-negative) 0%,
      var(--color-viz-neutral) 45%,
      var(--color-viz-neutral) 55%,
      var(--color-viz-positive) 100%
    );
  }

  .hmm-table thead {
    @apply tw-border-b tw-border-cloud;
  }

  .hmm-table th,
  .hmm-table td {
    @apply tw-p-2;
  }

  .hmm-table tbody tr {
    @apply tw-relative tw-border-b tw-border-cloud;
  }

  .hmm-table-hover tbody tr {
    @apply hover:tw-bg-cloud-4;
  }

  .hmm-sidebar-divider {
    @apply tw-bg-cloud-3 tw-p-4 tw-font-heading tw-text-lg;
  }

  /* Tabs */
  .hmm-tabs {
    @apply -tw-mb-px tw-flex tw-shrink-0 tw-list-none tw-flex-nowrap tw-gap-2 tw-overflow-x-auto tw-border-b tw-border-cloud-2 tw-px-2 tw-py-0 tw-text-center;
  }

  /* Modifiers - Use with .hmm-tabs */
  .hmm-tabs-fill .hmm-tab {
    @apply tw-flex-grow;
  }

  .hmm-tabs-large .hmm-tab {
    @apply tw-py-2;
  }

  .hmm-tabs-large .hmm-tab-link {
    @apply tw-py-2;
  }

  /* Tab */
  .hmm-tab {
    @apply tw-flex tw-py-2;
  }

  /* Tab Link */
  .hmm-tab-link {
    @apply tw-relative tw-flex tw-flex-grow tw-flex-nowrap tw-items-center tw-justify-center tw-gap-2 tw-text-nowrap tw-rounded tw-px-2 tw-py-0.5 tw-text-night tw-no-underline tw-transition-all tw-duration-150;
  }

  .hmm-tab-link:before {
    @apply tw-absolute -tw-bottom-[8px] tw-left-0 tw-flex tw-h-[2px] tw-w-full tw-rounded-none tw-bg-transparent tw-transition-all tw-duration-150;
    content: ' ';
  }

  .hmm-tab-link-active:before {
    @apply tw-bg-night;
  }

  .hmm-tab-link:hover {
    @apply tw-bg-cloud-4 tw-text-night;
  }

  .hmm-tab-link:focus-visible {
    @apply tw-bg-cloud-2;
  }
}
