// Primary button has incorrect font colour
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-outline-primary:hover {
  color: var(--h2m-white);
}

// Update Radio Button Active Styles
.btn-check:checked {
  + .btn-primary {
    background-color: var(--h2m-success);
    border-color: var(--h2m-success);
    color: var(--h2m-white);
  }

  + .btn-secondary {
    background-color: var(--h2m-gray);
    border-color: var(--h2m-gray);
    color: var(--h2m-white);
  }
}

// Remove outlines for focused modals
:focus-visible {
  outline: none;
}
