@use '@angular/cdk/overlay-prebuilt.css';
@use 'bootstrap-icons/font/bootstrap-icons.css';

// Incubator
@use 'uplot/dist/uPlot.min.css';

.uplot {
  font-family: var(--h2m-font-sans-serif);

  .u-value {
    font-family: var(--h2m-font-monospace);
    padding-bottom: 0;
  }
}

//Flagpack icons
//TODO promote to library
$fp-enable-1x1: true !default;
$fp-enable-4x3: false !default;
$fp-enable-rounded: false !default;
$fp-prepend: true !default;
$fp-4x3-path: 'flagpack/flags/4x3/';
$fp-1x1-path: 'flagpack/flags/1x1/';
//specify only subset of countries hmm has worked with as of Q12021
//see https://flagpack.xyz/
$fp-countries: 'ae', 'au', 'be', 'br', 'ca', 'cn', 'cz', 'de', 'eg', 'es', 'fr',
  'gb', 'id', 'in', 'it', 'it', 'jp', 'ke', 'kr', 'mx', 'my', 'ng', 'nl', 'pl',
  'sa', 'se', 'sg', 'us', 'za';

@import 'flagpack/src/flagpack.scss';

html {
  height: 100%;
}

body {
  min-height: 100%;
}

.form {
  .ng-select {
    .ng-select-container {
      height: 38px;
    }

    &.ng-select-disabled {
      .ng-select-container {
        background-color: #e9ecef;
      }
    }
  }
}
