// Brand colour names
$night: #27242c;
$leaf: #25d2ab;
$cloud: #e1e3f0;
$frost: #f6fbfd;
$sky: #73b0f4;
$sun: #fa7f51;
$slate: #abb4be;
$raspberry: #ff4e5c;

// Set the default colours
$primary: $leaf;
$secondary: $cloud;
$success: darken($leaf, 10%);
$info: $sky;
$warning: $sun;
$danger: darken($raspberry, 20%);
$light: $frost;
$dark: $night;

// Switch off rounded corners
$enable-rounded: false;

// Table Colours
$border-color: $cloud;

// Switch off shadows
$enable-shadows: false;

// Set the fonts
$font-family-sans-serif: 'TTInterphases', sans-serif;
$headings-font-family: 'TTFirsNeue', sans-serif;

// Buttons use heading font
$btn-font-family: $headings-font-family;

// Other variables
$variable-prefix: h2m-;

// Make our placeholders pale
$placeholder-opacity-max: 0.3;
$placeholder-opacity-min: 0.05;

// Set the z-index for modals under ng-selects panels
$zindex-modal-backdrop: 1048;
$zindex-modal: 1049;

@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins';
@import 'bootstrap/scss/_maps';
@import 'bootstrap/scss/_utilities';

// Add a opacity utility
$utilities: map-merge(
  $utilities,
  (
    // Create new utility for cursor pointer
    'cursor':
      (
        property: cursor,
        class: cursor,
        values: (
          'pointer': pointer,
        ),
      ),
    // Create new tl utility
    'table-layout':
      (
        property: 'table-layout',
        class: tl,
        values: (
          fixed: fixed,
        ),
      ),
    'direction': (
      property: 'direction',
      class: dir,
      values: (
        ltr: ltr,
        rtl: rtl,
      ),
    ),
    // Add font-families
    'font-family':
      map-merge(
        map-get($utilities, 'font-family'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'font-family'), 'values'),
              (
                heading: $headings-font-family,
                'sans-serif': $font-family-sans-serif,
              )
            ),
        )
      ),
    // Add background colours
    'background-color':
      map-merge(
        map-get($utilities, 'background-color'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'background-color'), 'values'),
              (
                'muted': $text-muted,
              )
            ),
        )
      ),
    // Add background opacities
    'bg-opacity':
      map-merge(
        map-get($utilities, 'bg-opacity'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'bg-opacity'), 'values'),
              (
                5: 0.05,
                90: 0.9,
              )
            ),
        )
      )
  )
);
